<template>
    <header class="mb-4 pb-4 bg-accent shadow-dark">
        <div class="flex items-center justify-end gap-2 h-14 md:h-20 mx-7 md:mx-16 mb-5">
            <HeaderAccountMenu v-if="$viewport.isGreaterOrEquals('md')" />
            <HeaderMobileMenu v-else />
        </div>
        <div class="flex flex-col items-center max-w-[1000px] mx-auto">
            <EnhancedImage
                v-if="$viewport.isGreaterOrEquals('md')"
                css-class="mx-auto block"
                :src="logoUrl"
                alt="TrustedParts.com"
                :high-res="true"
                height="88"
                width="600" />
            <EnhancedImage
                v-else
                css-class="mx-auto block"
                :src="logoUrl"
                alt="TrustedParts.com"
                :high-res="true"
                height="35"
                width="236" />
            <div class="text-center mt-4 md:mt-8 text-sm md:text-3xl text-white">{{ $t("Layout.SearchHeader") }}</div>
            <div class="mt-4 max-w-screen-lg w-full px-6" role="search">
                <HeaderSearchBox
                    v-if="$viewport.isGreaterOrEquals('md')"
                    :placeholder="$t('Layout.SearchPartNumberDefaultText')"
                    :language="locale"
                    :region="currentRegion.ShortName"
                    :is-home="true" />
                <HeaderMobileSearchBox v-else :language="locale" :region="currentRegion.ShortName" :is-home="true" />
            </div>
            <div v-if="$viewport.isGreaterOrEquals('lg')" class="flex gap-8 text-base my-8">
                <HeaderLanguageMenu />
                <HeaderCurrenciesMenu />
                <HeaderRegionsMenu />
            </div>
            <HeaderTaglines />
        </div>
    </header>
</template>
<script setup>
import { useNuxtApp } from "#app";
const { $viewport } = useNuxtApp();
const { locale } = useI18n();
const config = useRuntimeConfig();
const cdnUrl = config.public.cdnUrl;
const logoUrl = cdnUrl + "/assets/images/trusted-parts-logo-home.png";

const currentRegion = useCurrentRegion();
</script>
